import React from "react";
import AscComponent from "../../../components/AscComponent";
import { Col, Row, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";

export default class CallResultCharts extends AscComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            toggles: {},
            data: [],
            total_call_result_count: 0
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.data !== this.props.data) {
            this.onChartDataChange();
        }
    }

    onChartDataChange = () => {
        this.setState({
            total_call_result_count: this.props.data.call_result.total_call_result_count,
            data: this.props.data.call_result.data,
            toggles: {}
        });
    }

    toggleChange = (toggle) => {
        this.setState({
            toggles: {
                ...this.state.toggles,
                [toggle]: !this.state.toggles[toggle]
            }
        });
    }

    getItemInfo = (item, idx, parentKey="", num=-1) => {
        const key = `${parentKey}${idx}`
        const isToggle = this.state.toggles[key];
        const isChild = parentKey !== "";
        num++;
        return (
            <>
                <tr key={key} onClick={() => this.toggleChange(key)} className={isChild ? "child-tr" : ""}>
                    <td style={{ paddingLeft: isChild ? `${30 * num}px` : "8px" }}>{item.children ? (isToggle ? 
                        this.props.langText.Body.UnderTriangle 
                        : this.props.langText.Body.RightTriangle) : ""} {item.name}</td>
                    <td>{item.count}{this.props.langText.Body.TimeCount}</td>
                    <td>{item.percentage}{this.props.langText.Body.Percent}</td>
                </tr>
                {isToggle && item.children && (
                    item.children.map((childItem, childIdx) => 
                        this.getItemInfo(childItem, childIdx, `${key}-`, num))
                )}
                {!isChild && key != this.state.data.length - 1 && (
                <tr className="default-tr">
                    <td colSpan="3">
                        <hr style={{ border: "1px solid #E6E6E6", margin: "0" }} />
                    </td>
                </tr>
                )}
            </>
        )
    }

    render() {
        return (
            <div className="call-result-chart">
                <Row className="call-result-chart-header">
                    <Col xs={8}>{this.props.langText.Body.CallResult}</Col>
                    <Col xs={2} className="padding-right-0 margin-left-1">{this.props.langText.Body.TotalCallResultCount}{this.state.total_call_result_count}件</Col>
                    <Col xs={1} className="padding-bottom-03">
                        <Button
                            id="call-result-csv-download" 
                            className="analysis-summary-csv-download-button"
                            onClick={() => this.props.DownloadData("call_result")}
                        >
                            <FontAwesomeIcon
                                icon={faIcon.faCloudDownloadAlt}
                                size="lg"
                            />
                        </Button>
                        <CSVLink
                            data={this.props.createDownloadData(null, "call_result")}
                            filename={this.props.csvTitle}
                            className="csv-download-link-format hidden"
                            ref={this.props.csvLinkCallResult}
                        />
                    </Col>
                </Row>
                <div className="call-result-chart-table-wrapper">
                    <Table bordered hover className="call-result-chart-table">
                        <tbody>
                            {this.state.data.map((row, idx) => this.getItemInfo(row, idx))}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}
