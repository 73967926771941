import React from "react";
import AscComponent from "../../../components/AscComponent";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import SetDataWithSpeechBubble from "../../Elements/AscElements/SetDataWithSpeechBubble";
import * as faIcon from "@fortawesome/free-solid-svg-icons";

export default class DirectionRadarChart extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            categories: ['call_time', 'silence_count', 'talk_per', 'talk_conflict_count', 'speech_speed_diff'],
            series: [{
                data: []
            }],
            options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: ['通話時間', '沈黙回数', 'Talk 比率', '被り回数', '話速の差'],
                    labels: {
                        style: {
                            colors: ['#000', '#000', '#000', '#000', '#000'],
                            fontSize: '13px'
                        }
                    }
                },
                markers: {
                    size: [0],
                },
                yaxis: {
                    show: false,
                    min: 0,
                    max: 200
                },
                legend: {
                    show: false
                },
                tooltip: {
                    y: {
                        title: {
                            formatter: function() {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                },
                fill: {
                    opacity: 0.1
                }
            },
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.data !== this.props.data    
        ) {
            if (this.props.compareFlag) {
                this.onChartDataCompareChange();
            } else {
                this.onChartDataChange();
            }
        }
    }

    onChartDataChange = () => {
        const {
            data
        } = this.props;

        let default_series = [{
            data: [100, 100, 100, 100, 100],
            color: '#2E9AFE'
        }];
        let dataArr = [];
        let nameArr = [];
        let max = 200;
        let fill = {}

        data.forEach(row => {
            if (max < row.value) max = row.value;
            dataArr.push(row.value);
        });

        if (dataArr.length > 0) {
            default_series[0].color = '#BDBDBD';
            fill = {
                opacity: [0, 0.1]
            }
        }

        this.setState({
            options: {
                ...this.state.options,
                markers: {
                    ...this.state.options.markers,
                    size: [0, 2]
                },
                yaxis: {
                    ...this.state.options.yaxis,
                    max
                },
                fill: {
                    ...this.state.options.fill,
                    ...fill
                }
            },
            series: [
                ...default_series,
                {
                    data: [...dataArr],
                    color: '#2E9AFE'
                }
            ],
        });
    }

    onChartDataCompareChange = () => {
        const {
            data
        } = this.props;
        const categories = this.state.categories;

        let max = 200;
        let fill = {
            opacity: []
        }
        
        let default_series = {
            data: [100, 100, 100, 100, 100],
            color: '#2E9AFE'
        };

        let series = [];
        if (data && data.length > 0) {
            data.forEach((row, index) => {
                let series_data = [];
                let series_temp = {};
                series_temp.name = index === 0 ? `${this.props.langText.Body.CompareA}` : `${this.props.langText.Body.CompareB}`;
                series_temp.color = index === 0 ? '#2E9AFE' : '#FFB366';
                if (row && row.length > 0) {
                    categories.forEach(item => {
                        let temp_obj = row.find(row => row.key === item);
                        if (temp_obj) {
                            if (max < temp_obj.value) max = temp_obj.value;
                            series_data.push(temp_obj.value);
                        }
                    });
                } else if (row && row.length === 0) {
                    series.push(default_series);
                }
                series_temp.data = series_data;
                fill.opacity.push(0.1);
                series.push(series_temp);
            });
        }

        if (data && (data[0] && data[0].length > 0 ) && (data[1] && data[1].length > 0)) {
            default_series.color = '#BDBDBD';
            fill.opacity.push(0);
            series.push(default_series);
        }

        this.setState({
            options: {
                ...this.state.options,
                markers: {
                    size: [2, 2, 0]
                },
                yaxis: {
                    ...this.state.options.yaxis,
                    max
                },
                fill: {
                    ...fill
                },
                tooltip: {
                    ...this.state.options.tooltip,
                    y: {
                        title: {
                            formatter: function(seriesName) {
                                return seriesName + " : ";
                            }
                        }
                    },
                    marker: {
                        show: true
                    }
                }
            },
            series: [
                ...series
            ],
        });
    }

    render() {
        return (
            <div className="common-line-chart padding-left-1em">
                <div>
                    <div className="common-line-chart-title">
                        <SetDataWithSpeechBubble
                            displayData = {this.props.title}
                            speechBubbleData = {this.props.description}
                            infoIconDisplayFlag = {true}
                            infoIconType = {faIcon.faInfoCircle}
                            infoIconClassName = "info-icon"
                            infoIconColor = "silver"
                            infoIconSize = "lg"
                            custom="custom"
                        />
                    </div>
                </div>
                <Chart options={this.state.options} series={this.state.series} type="radar" height={this.props.height}/>
            </div>
        );
    }
}
